import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: () => import("@/views/index"),
    name: "index",
  },
  {
    path: "*",
    redirect: "/index",
  },
  {
    path: "/detail",
    component: () => import("@/views/detailIndex"),
    name: "detail",
    children: [
      {
        path: "/",
        component: () => import("@/views/about/aboutDetail"),
        name: "aboutDetail",
      },
      {
        path: "/detail/aboutDetail",
        component: () => import("@/views/about/aboutDetail"),
        name: "aboutDetail",
      },
      {
        path: "/detail/starDetail",
        component: () => import("@/views/star/starDetail"),
        name: "starDetail",
      },
      {
        path: "/detail/worksDetail",
        component: () => import("@/views/works/worksDetail"),
        name: "worksDetail",
      },
      {
        path: "/detail/courseDetail",
        component: () => import("@/views/course/courseDetail"),
        name: "courseDetail",
      },
      {
        path: "/detail/teamDetail",
        component: () => import("@/views/about/teamDetail"),
        name: "teamDetail",
      },
    ],
  },
];
const router = new VueRouter({
  routes,
});

export default router;
