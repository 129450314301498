import Vue from 'vue'
import App from './App.vue'
// import VueLazyLoad from 'vue-lazyload'
import router from "./router/index"

// import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js'


Vue.config.productionTip = false

Vue.use(VueFullPage)

// Vue.use(VueLazyLoad, { // 注意：此项一定要写在new Vue（{}）之前，否则会报错：[Vue warn]: Failed to resolve directive: lazy
//   preload: 1.3, // 预加载高度比例
//   error: require('@/assets/open-future.png'), // 图片路径出错时加载图片 此处
//   loading: require('@/assets/open-future.png'), // 预加载图片
//   attempt: 5, // 尝试加载图片数量
//   observer: true,
//   observerOptions: {
//       rootMargin: '0px',
//       threshold: 0.1
//   }
// })

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
