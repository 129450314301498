<template>
  <div id="app">
    <router-view />
  </div>
</template>
  
<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>
  
<style lang="less">
body,
html,
p {
  margin: 0px;
  padding: 0px;
}

// * {
//   cursor: url(/src/assets/image/logo.png), default;
// }

body,
html {
  width: 100vw;
  height: 100vh;
  /* height: 100%; */
  background-color: #12151a;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "PingFang SC",
    "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #12151a;
}

::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

div img {
  width: 100%;
  height: 100%;
}

div {
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
::-webkit-scrollbar {
  display: none;
}
</style>
  